'use client';

import { atom } from 'jotai';

type PreferredTime = {
  value: string;
  label: string;
};

export type LeadFormState = {
  optIns: any;
  title?: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  countrycode?: string;
  postcode: string;
  message?: string;
  date?: string; // if this is always a date string, you may want to use Date instead
  preferredTime?: PreferredTime;
  kaisaTime?: PreferredTime;
  registration?: string;
  mileage?: number;
  marketingOptIns?: boolean;
  oneClickChkBox?: boolean;
  oneClickDriftrockChkBox?: boolean;
  driftrockChkBox?: boolean;
  reserveVehicleChkBox?: boolean;
  financeQuoteChkBox?: boolean;
  emailOptins?: boolean;
  callBackInstantlyChkBox?: boolean;
  callBackDuringDayChkBox?: boolean;
};

export type LeadFormStateKey = keyof LeadFormState;

export const formStateAtom = atom<LeadFormState>({} as LeadFormState);
